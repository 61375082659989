/*
 * @Author: ljf
 * @Date: 2022-06-14 09:59:24
 * @LastEditors: ljf
 * @LastEditTime: 2022-06-15 09:43:46
 * @FilePath: \lxm-admin\src\components\common\dialog\index.js
 * @Description: 
 * 
 */
import  ExDialog from "./dialog.vue";

export default {
  install: (Vue) => {
    Vue.component('ex-dialog', ExDialog);
  }
}