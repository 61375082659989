import Vue from "vue";
import Vuex from "vuex";
import AxiosHelper from "@/utils/axiosHelper";
import router from '../router'
import persist from 'vuex-persistedstate'
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {

  },
  state: {
    user: null, // 当前登入用户信息对象
    isLogining: false, // 是否正在登录中,
    collapse: false, // 是否收起侧边栏,
    tagsList: [], // 标签页列表,
    firstPath:'', // 首次进入的路由
    temp: [],
    this_router: {},
    is_refresh: false,
    hasMessage: false,
    messageTimer: null,
    editFormData: {},
    isDetailFormLoading: false,
    routerCache:{
      subsidy_detail:''
    },
     viewportHeight:window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

  },
  getters: {
    user (state) {
      return state.user;
    },
    isLogining (state) {
      return state.isLogining;
    },
    collapse (state) {
      return state.collapse;
    },
    tagsList (state) {
      return state.tagsList.sort((a, b) => {
        return b.time - a.time;
      });
    },
    hasNewMessage (state) {
      return state.hasMessage;
    },
    messageTimer (state) {
      return state.messageTimer;
    },
    platform (state) {
      return state.platform
    },
    isDetailFormLoading (state) {
      return state.isDetailFormLoading
    }
  },
  mutations: {
    setIsRefresh (state, par) {
      state.is_refresh = par;
    },
    setRouter (state, par) {
      state.this_router = par;
    },
    /**
     * [设置登入用户信息]
     * @param  {[Object]} user [当前登录用户对象]
     */
    setUser (state, user) {
      //临时使用
      if(user.roleCodes.indexOf("dailishang")!=-1){
        user.isAgent=true
      }else{
        user.isAgent=false
      }
      state.user = user;
    },
    /**
     * [设置是否正在登录]
     * @param {[type]}  state      [description]
     * @param {Boolean} isLogining [description]
     */
    setLoadinging (state, isLogining) {
      state.isLogining = isLogining;
    },
    /**
     * [是否收起侧边栏]
     * @param {[type]}  state      [description]
     * @param {Boolean} collapse [description]
     */
    setCollapse (state, collapse) {
      state.collapse = collapse;
    },
    setTagsByRoute (state, route) {
      var { id } = route.query
      var tag = {
        title: route.params.title || route.meta.title,
        path: route.path,
        fullPath: route.fullPath,
        //TODO 这个取最后一个
        name: route.name,
        componentName: route.matched[1].components.default.name || route.matched[1].name,
        id: id,
        query: route.query,
        params: route.params
      }

      const isInTags = state.tagsList.some(item => {
        return item.fullPath === route.fullPath || (!!id && item.id === id)
      })

      if (!isInTags) {
        if (state.tagsList.length >= 8) {
          state.tagsList.shift()
        }
        state.tagsList.push(tag)
      }
    },
    delTags (state, tag) {
      if (tag) {
        var index = state.tagsList.findIndex(t => {
          return t.fullPath === tag.fullPath
        })
        state.tagsList.splice(index, 1)
      }
    },
    goBack (state, route) {
      var { name, query, params } = JSON.parse(route?.params?.reRouter || {})

      if (name) {
        router.push({
          name: name,
          query: query,
          params: {
            ...params,
            refresh: 1
          }
        })
      }
    },
    setFirstPath(state,path){
      state.firstPath = path
    },
    setTagsByList (state, list) {
      state.tagsList = list;
    },
    setTemp (state, value) {
      state.temp.push(value);
    },
    updateHasMessage (state, hasMessage) {
      state.hasMessage = hasMessage;
    },
    setMessageTimer (state, messageTimer) {
      state.messageTimer = messageTimer;
    },
    setEditFormData (state, editFormData) {
      state.editFormData = editFormData;
    },
  },
  actions: {
    delTags ({ commit, state }, tag) {
      return new Promise(resolve => {
        commit('delTags', tag)
        resolve([...state.tagsList])
      })
    },
    goBack ({ commit, state }, route) {
      return new Promise(resole => {

        commit('delTags', route)
        commit('goBack', route)
      })
    },

    startDetailFormLoading ({ state }) {
      state.isDetailFormLoading = true;
    },
    endDetailFormLoading ({ state }) {
      state.isDetailFormLoading = false;
    },
  },
  plugins: [
    new persist({
      storage: window.sessionStorag,
    }),
  ]
});
