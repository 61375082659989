/*
 * @Author: ljf
 * @Date: 2022-04-12 17:01:37
 * @LastEditors: ljf
 * @LastEditTime: 2022-07-14 14:02:40
 * @FilePath: \lxm-admin\src\utils\axiosHelper.js
 * @Description:
 *
 */
import axios from "axios";
import { Message } from "element-ui";
import Cookies from "js-cookie";
import vue from "vue";
axios.create({
  timeout: 5000,
});
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
switch (process.env.NODE_ENV) {
  case "development":
    axios.defaults.baseURL = "/dev";
    break;
  case "production":
    axios.defaults.baseURL = "/";
    break;
  default:
    break;
}
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.withCredentials = true;

// var loadingInstance
axios.interceptors.request.use(
  (config) => {
    //Ajax请求执行该方法，请求带上token
    var token = Cookies.get("userToken");
    if (token !== null && token !== undefined && token !== "") {
      config.headers["Authorization"] = "Bearer " + token;
      config.headers["Request-Id"] = BigInt(
        Math.floor(100000000000000000 + Math.random() * 900000000000000000)
      ).toString();
      config.headers["App-Channel-Id"] = "100101";
      config.headers.Timestamp = Number(new Date());
      var host = window.location.host;
      config.headers["appHost"] = host;
      config.headers["appId"] = Cookies.get("appId");
    }
    //全局配置，get请求加时间戳
    if (config.method.toLowerCase() === "get") {
      config.url += config.url.match(/\?/) ? "&" : "?";
      config.url += "_dc=" + new Date().getTime();
    }
    return config;
  },
  (error) => {
    //请求错误处理

    Message.error({
      message: "加载超时",
    });
    return Promise.reject(error);
  }
);

var count = 0;
axios.interceptors.response.use(
  (response) => {
    // 判断一下响应中是否有 token，如果有就直接使用此 token 替换掉本地的 token。你可以根据你的业务需求自己编写更新 token 的逻辑
    var token = response.headers.freshtoken;
    if (token) {
      // 如果 header 中存在 token，那么触发 refreshToken 方法，替换本地的 token
      Cookies.set("userToken", token);
    }
    var userInfo = response.headers.userinfo;
    if (userInfo) {
      Cookies.set("userInfo", decodeURIComponent(userInfo));
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      if (count === 0) {
        count = count + 1;
      } else if (count > 0) {
        return null;
      }
      //eslint-disable-next-line no-debugger
      Message.error("身份信息超时，请重新登录！", { icon: 1, time: 2000 });
      Cookies.remove("userToken");
      localStorage.clear();
      setTimeout(function () {
        //跳转到登录页
        var host = window.location.host;
        if (host.indexOf("http://") == -1) {
          host = "http://" + host;
        }
        window.location.href = host;
      }, 3000);
    }
    return Promise.reject(error);
  }
);

export default axios; //暴露axios实例
